.project-container {
    width: 350px;
    height: 350px;
    border-radius: 15px;
    box-shadow: 0px 10px 30px rgba(0, 0, 0, 0.1);
    overflow: hidden;
    margin-bottom: 64px;
    cursor: pointer;
}

.project-container:hover {
    transform: scale(1.01);
}

.project-img {
    width: 90%;
    height: 90%;
}

.project-img-troca {
    background-image: url('../assets/images/troca.png');
}

.project-img-marla {
    background-image: url('../assets/images/marla.png');
}

.project-modal-description {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
}

.project-modal-description ul {
    padding-left: 0px;
}

@media (min-width: 768px) { 
    .project-container {
        width: 400px;
        height: 400px;
        margin: 16px 48px 32px 32px;
    }
}

@media (min-width: 1200px) { 
    .project-modal-description {
        flex-direction: row;
    }

    .project-modal-description ul {
        padding-left: 2rem;
    }
}