.custom-container {
    width: 100%;
    max-width: 1200px;
    padding: 32px;
}

.app-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.app-full-screen {
    min-height: calc(100vh - 80px)
}

.text-gradient {
    background: var(--main-gradient);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    color: transparent;
}

.custom-bg-gradient {
    background: var(--main-gradient);
}

.main-bg-img {
    margin-top: 48px;
    width: 350px;
    height: 350px;
    background-image: url('../assets/images/computer.png');
}

.custom-img {
    background-position: center;
    background-repeat: no-repeat;
}

.custom-img-contain {
    background-size: contain;
}

.modal-content {
    background-color: whitesmoke !important;
}

.modal-header {
    border-bottom: 0px !important;
}

.custom-modal {
    width: 100%;
    height: 700px;
    overflow-y: auto;
    overflow-x: hidden;
}

@media (min-width: 768px) { 
    .main-bg-img {
        width: 500px;
        height: 500px;
    }

    .custom-modal {
        height: 500px;
    }
}

@media (min-width: 992px) {
    .app-container {
        flex-direction: row;
        justify-content: space-around;
    }

    .main-bg-img {
        margin-top: 0px
    }
}

@media (min-width: 1200px) { 
    .app-full-screen {
        min-height: 600px
    }
}

@media (min-width: 1400px) { 
    .app-container {
        justify-content: space-between;
    }
}