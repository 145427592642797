@font-face {
  font-family: 'Namart';
  src: url('/src/assets/fonts/Roboto-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Namart';
  src: url('/src/assets/fonts/Roboto-Bold.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
}

:root {
  --primary-color: #0B67AA;
  --secondary-color: #50A9DD;
  --main-gradient: linear-gradient(270deg, #0B67AA 0%, #50A9DD 50.91%, #0B67AA 100%);
}

body {
  margin: 0;
  font-family: 'Namart', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: whitesmoke;
  color: #000;
  font-size: 16px;
}

code {
  font-family: 'Namart', sans-serif;
}

.bg-dark {
  background-color: #000 !important;
}

h2 {
  font-size: 56px;
  font-weight: bold !important;
  margin-bottom: 16px;
}

h3 {
  font-size: 40px;
  font-weight: bold !important;
}

h4 {
  font-size: 24px;
  font-weight: bold !important;
}

p {
  font-size: 18px;
}

a {
  color: #000;
}

main,
section {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}