.custom-btn-container {
    min-width: 150px;
    height: 50px;
    padding: 16px;
    font-size: 16px;
    text-align: center;
    font-weight: bold;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}

.custom-btn-primary {
    background-color: var(--primary-color);
    color: #fff;
}

.custom-btn-primary:hover {
    background-color: var(--secondary-color);
}

.custom-btn-secondary {
    background-color: transparent;
    border: 1.5px solid;
    border-color: #000;
    color: #000;
}

.custom-btn-secondary:hover {
    background-color: #000;
    color: #fff;
}

.custom-btn-alternative {
    background-color: #fff;
    color: #000;
}

.custom-btn-alternative:hover {
    background-color: #000;
    color: #fff;
}