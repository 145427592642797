.custom-footer-container {
    background-color: #000;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: #fff;
    padding: 24px;
}

.custom-footer-container div {
    max-width: 1200px;
}

.custom-footer-info {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.custom-footer-logo {
    width: 200px;
    height: 100px;
    background-image: url('../assets/images/logo.png');
    cursor: pointer;
}

.custom-footer-action {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: start;
    background: var(--main-gradient);
    margin: 24px;
    padding: 24px;
}

.custom-footer-action div {
    width: 100%;
    margin: 8px;
}

.custom-footer-icon {
    margin: 16px;
    cursor: pointer;
}

.custom-footer-icon:hover {
    transform: scale(1.1);
}

@media (min-width: 992px) {
    .custom-footer-info {
        flex-direction: row;
        justify-content: space-around;
    }

    .custom-footer-action {
        flex-direction: row;
        align-items: center;
    }

    .custom-footer-action div {
        width: 50%;
    }

    .custom-footer-logo {
        width: 300px;
        height: 150px;
    }
}