  .custom-navbar .navbar-brand,
  .custom-navbar .nav-link  {
      color: #fff;
  }
  
  .custom-navbar .navbar-toggler-icon {
      background-color: #000;
  }
  
  .custom-navbar .navbar-toggler {
      border-color: #000;
  }
  
  .custom-nablink {
    position: relative;
    color: #fff;
    text-decoration: none;
  }

  .custom-nablink:hover {
    color: #fff !important;
  }

  .custom-nablink:focus {
    color: #fff !important;
  }

  #basic-nav-dropdown,
  #basic-nav-dropdown:hover,
  #basic-nav-dropdown:focus {
    color: #fff !important;
  }
  
  .custom-nablink::after {
    content: '';
    position: absolute;
    width: 0;
    height: 2px;
    left: 0;
    bottom: 0px;
    background: var(--main-gradient);
    transition: width 0.3s ease-in-out;
  }

  .custom-nablink:hover::after,
  .custom-nablink:focus::after {
    width: 25%;
  }

  .menu-logo {
    background-image: url('../assets/images/logo.png');
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
    width: 200px;
    height: 50px;
  }

  @media (min-width: 992px) {
    .custom-nablink:hover::after,
    .custom-nablink:focus::after {
      width: 90%;
    }
  }