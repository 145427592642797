.project-card-container {
    width: 300px;
    min-height: 450px;
    background-color: #fff;
    margin: 24px;
    padding: 32px;
}

.project-card-container:hover {
    transform: scale(1.01);
}

.project-card-container:hover .project-card-icon i {
    transform: rotate(360deg);
    transition: transform 0.3s ease;
}

.process-card-container {
    width: 300px;
    height: 320px;
    background-color: #fff;
    margin: 24px;
    padding: 32px;
}

.process-card-container:hover {
    transform: scale(1.01);
}

.project-card-icon {
    background: var(--main-gradient);
    width: 60px;
    height: 60px;
    border-radius: 15px;
    box-shadow: 0px 10px 30px rgba(0, 0, 0, 0.1);
}